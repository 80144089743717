export default function setupLazyLoad(intersectionTarget, func) {
  if (!intersectionTarget || !func) return;
  var intersectionObserver = new window.IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) func();
    });
  }, {
    rootMargin: "400px 0px"
  });
  intersectionObserver.observe(intersectionTarget);
}